<template>
    <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}
body {
    padding: 0;
    margin: 0;
    color: rgb(18, 30, 47);
    font-family: 微软雅黑,serif;
}

h1, h2, h3, h4, h5 {
    margin: 0;
}

ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

u {
    text-decoration: none;
}
</style>
