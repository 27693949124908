import {createRouter, createWebHashHistory} from 'vue-router'


export default createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('../components/HomePage'),
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('../components/ServiceSupport')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../components/AboutUs')
    },
    {
      path: '/cases',
      name: 'cases',
      component: () => import('../components/CustomerCase')
    },
    {
      path: '/product/vision',
      name: 'vision',
      component: () => import('../components/products/DataVision')
    },
    {
      path: '/product/iot',
      name: 'iot',
      component: () => import('../components/products/IoT')
    },
    {
      path: '/product/dome',
      name: 'dome',
      component: () => import('../components/products/SkyDome')
    },
    {
      path: '/product/solar',
      name: 'solar',
      component: () => import('../components/products/SolarLamp')
    },
    {
      path: '/product/pump',
      name: 'pump',
      component: () => import('../components/products/PumpHouse')
    },
    {
      path: '/product/city',
      name: 'city',
      component: () => import('../components/products/DigitalCity')
    },
    {
      path: '/solution/park',
      name: 'park',
      component: () => import('../components/solutions/SmartPark')
    },
    {
      path: '/solution/camp',
      name: 'camp',
      component: () => import('../components/solutions/SmartCamp')
    },
    {
      path: '/solution/fire',
      name: 'fire',
      component: () => import('../components/solutions/FirePrevention')
    },
    {
      path: '/solution/health',
      name: 'health',
      component: () => import('../components/solutions/SmartHealth')
    },
  ]
})
